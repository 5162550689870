import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import cn from 'classnames'
import SEO from '../../components/seo'
import NewsSection from '../../components/NewsSection'
import * as s from '../../pages/news.module.scss'
import Link from '../../components/Common/Link'

const GreenSuccessStorie: React.FC = () => {
  return (
    <>
      <SEO
        title="История успеха использования SIGNAL в ГК GREEN"
        description="Как использую сервисы SIGNAL в ГК GREEN - Интервью с руководителем BIM отдела и разработки ПО"
      />
      <NewsSection
        title="История успеха использования SIGNAL в ГК GREEN"
        dateData={{
          date: '22.11.2023',
        }}
        rayContent
      >
        <StaticImage
          width={300}
          className="my-4"
          src="../../assets/images/news/green-success-storie/green.jpg"
          alt="ГК GREEN"
          title="ГК GREEN"
          placeholder="blurred"
        />
        <p className={s.text}>
          Продукты компании SIGNAL уже более 3-х лет помогают девелоперам,
          представителям службы строительного контроля и технического заказчика
          оцифровать базовые процессы по ведению строительной информационной
          модели, электронного документооборота и контролю строительных
          объектов. Мы всегда на связи с нашими клиентами, учитываем их мнение и
          ценим их вклад в развитие наших продуктов и считаем, что кто, как ни
          они, могут рассказать о преимуществах и недостатках использования
          наших продуктов. Мы пообщались с представителем одного из наших давних
          клиентов с Сергеем Киселевым, руководителем BIM отдела и разработки ПО
          в группе компаний GREEN. Они уже около двух лет используют наши
          продукты на своих проектах, а мы узнали как именно
        </p>
        <div className="flex items-center flex-wrap">
          <StaticImage
            width={150}
            className="my-4 mr-4"
            src="../../assets/images/news/green-success-storie/kiselyov.jpg"
            alt="Сергей Киселев"
            title="Сергей Киселев"
            placeholder="blurred"
          />
          <div>
            <p className={cn(s.text, 'm-0')}>Сергей Киселев</p>
            <p className={cn(s.text, 'm-0')}>
              Руководитель BIM отдела и разработки ПО ГК GREEN
            </p>
          </div>
        </div>
        <h3 className={cn('font-w-600', s.text)}>
          Расскажите подробнее о компании, чем занимаетесь?
        </h3>
        <p className={s.text}>
          Мы, группа компании{' '}
          <Link
            href="https://greenfingroup.com/"
            rel="nofollow noreferrer"
            target="_blank"
          >
            GREEN
          </Link>{' '}
          - международная инжиниринговая компания осуществляющая комплексное
          сопровождение инфраструктурных, промышленных и девелоперских проектах.
          У нас есть разные направления - это Проектирование, Технический и
          финансовый консалтинг, Управление строительными проектами, т.е. мы
          можем охватывать и проектирование и строительный контроль отдельно, и
          функцию технического заказчика также ведем.
        </p>
        <h3 className={cn('font-w-600', s.text)}>
          Если не секрет, где представлены на международном рынке?
        </h3>
        <p className={s.text}>
          У нас есть офисы в Индии, Казахстане и Узбекистане, объекты в
          Казахстане, Монголии и в целом в ближней Азии. По России у нас объекты
          по всей стране - Москва, Санкт-Петербург, Калининградская, Амурская,
          Новосибирская, Сахалинская области и др.
        </p>
        <p className={s.text} style={{ color: '#b6bec7' }}>
          (прим. ГК GREEN в качестве среды общих данных для своих проектов
          использует Autodesk BIM 360 и CONSTRUCTION CLOUD)
        </p>
        <h3 className={cn('font-w-600', s.text)}>
          Какие нестандартные задачи приходилось решать?
        </h3>
        <p className={s.text}>
          Здесь тяжело выделить какие-то прям супер нестандартные. Мне кажется,
          что задачи у всех плюс-минус одинаковые. Мы с облаками точек работаем,
          поверхности проектируем, активно внедряли фотограмметрию на наших
          объектах в автоматическом режиме с помощью квадрокоптеров и на
          основании этих данных смотрели разницу по объекту. Мы стараемся с
          заказчиком на ранних стадиях обсудить все вопросы и договориться о
          всех хотелках, поэтому каких-то нестандартных задач или ситуаций у нас
          не возникает.
        </p>
        <h3 className={cn('font-w-600', s.text)}>
          Какие программы используете для создания проектов и их реализации?
        </h3>
        <p className={s.text}>
          В своих проектах мы полностью на Autodesk, Revit, Civil, Navisworks,
          какие-то концепции делаем в Infraworks, облака точек в ReCap PRO, в
          направлении управления строительными проектами, мы разное ПО
          используем для стройконтроля, в т.ч. SIGNAL, его мы используем в
          полном объеме на всех своих проектах. Вся работа строится вокруг ACC
          DOCS (прим. ред: АСС - Autodesk Construction Cloud) и плюс для
          совместной работы проектировщиков мы используем Collaborate Pro, а
          также наш УСП - Управление Строительными Проектами тоже все свои
          проекты ведут через DOCS.
        </p>
        <div className="flex">
          <StaticImage
            className={cn(s.imageBlock, 'mb-0')}
            src="../../assets/images/news/green-success-storie/image-1.jpg"
            alt="Аналитика SIGNAL DASHBOARD"
            title="Аналитика SIGNAL DASHBOARD"
            placeholder="blurred"
          />
        </div>
        <p className={cn(s.text, 'has-text-centered')}>
          Аналитика SIGNAL DASHBOARD
        </p>
        <h3 className={cn('font-w-600', s.text)}>
          Как давно используете технологию BIM?
        </h3>
        <p className={s.text}>
          Мы начали в конце 2020 года, активно стали внедрять с начала 2021 и
          уже полтора года спустя мы могли похвастаться реализацией всех своих
          разделов в BIM. Сейчас все текущие проекты полностью разрабатываются в
          Revit, за исключением каких-то спецразделов, которые в BIM реализовать
          сложно, например охрана окружающей среды, если надо разработать
          какие-то схемы, то для этого мы используем AutoCAD.
        </p>
        <h3 className={cn('font-w-600', s.text)}>
          А куда-то дальше пошли с использованием BIM-технологий, например может
          4/5D BIM?
        </h3>
        <p className={s.text}>
          Все зависит от задач, потому что не всем заказчикам это надо. У нас
          был опыт привязки календарного графика к элементам моделей,
          отслеживания временных коллизий, мы также делали визуализацию
          передвижение техники по строительной площадке, т.е. подобие ПОСа в 3D
          воспроизводили. Привязку сметной документации к моделям в рамках
          текущих проектов не делали, но в целом у команды есть такой опыт.
          Одним словом, там где-то у проектировщиков BIM-отдела такие задачи
          были, мы это делали и в целом, потихонечку к этому идем.
        </p>
        <h3 className={cn('font-w-600', s.text)}>
          Что из продуктов SIGNAL используете в своих проектах?
        </h3>
        <p className={s.text}>
          В первую очередь это SIGNAL INSPECTION - это вообще must have для нас.
          Он на 100% на всех наших проектах используется и DASHBOARD. VALUE на
          части проектов мы использовали, но не всем электронный документооборот
          интересен, потому что некоторые заказчики настаивали, чтобы это было в
          стандартное виде, бумажки, подписи, штампы и т.п. C некоторыми удается
          договориться, и например, Замечания из Construction Cloud фиксировать
          в VALUE. Но мы в любом случае всегда показываем, как это может
          работать, а дальше заказчик сам решает готов он на это идти или нет,
          нравится ему такое взаимодействие или нет, а у кого-то подрядчики не
          готовы или на стройке кто-то не готов, в общем история пока
          переходная.
        </p>
        <div className="flex">
          <StaticImage
            className={cn(s.imageBlock, 'mb-0')}
            src="../../assets/images/news/green-success-storie/image-2.jpg"
            alt="Контроль работ SIGNAL INSPECTION"
            title="Контроль работ SIGNAL INSPECTION"
            placeholder="blurred"
          />
        </div>
        <p className={cn(s.text, 'has-text-centered')}>
          Контроль работ SIGNAL INSPECTION
        </p>
        <h3 className={cn('font-w-600', s.text)}>
          Вы SIGNAL используете как свое конкурентное преимущество или как
          дополнительную услугу?
        </h3>
        <p className={s.text}>
          Мы используем и позиционируем это все, как преимущество работы с нами.
          В этом плане INSPECTION очень сильно помогает продавать проекты. Когда
          мы показываем, что мы делаем, мы говорим, вот смотрите примеры, можно
          сравнить, можно модель загрузить и уже с ней сравнить, как должно
          выглядеть. Уточняют с какой периодичностью это можно делать, тут мы
          готовы хоть каждый день это делать. В дальнейшем, разумеется это
          позволяет нам, с этим заказчиком подписать договор.
        </p>
        <h3 className={cn('font-w-600', s.text)}>
          Как раньше осуществляли контроль за ходом строительных работ, до
          использования SIGNAL?
        </h3>
        <p className={s.text}>
          Да как у всех я думаю, тратили много времени на физическое посещение
          стройплощадки и фиксировали что сделано, что не сделано, где-то
          фоткали и потом эти фотографии где-то хранились. В общем много лишних
          действий делали и волокиты. Потом попробовали SIGNAL, купили камеру
          360. Первый объект на котором попробовали внедрять это был проект
          автомобильной дороги в Подмосковье, мы там строительный контроль
          осуществляли. Но столкнулись с нюансом использования камеры 360,
          неудобно с ней по дороге ходить, объект довольно протяженный, поэтому
          мы решили квадрокоптеры использовать. На этом же объекте впервые
          начали дашборды заполнять, заказчику это очень понравилось - с
          телефона отслеживать ход работ, и на первых этапах это было прям очень
          круто.
        </p>
        <div className="flex">
          <StaticImage
            className={cn(s.imageBlock, 'mb-0')}
            src="../../assets/images/news/green-success-storie/image-3.jpg"
            alt="Применение SIGNAL на линейных объектах"
            title="Применение SIGNAL на линейных объектах"
            placeholder="blurred"
          />
        </div>
        <p className={cn(s.text, 'has-text-centered')}>
          Применение SIGNAL на линейных объектах
        </p>
        <h3 className={cn('font-w-600', s.text)}>
          То есть вы не только на гражданских объектах используете SIGNAL, но и
          на инфраструктурных?
        </h3>
        <p className={s.text}>
          Да, мы вообще начинали внедрять SIGNAL на линейных объектах. Правда
          адаптировали немного под себя, карточки заполняли, показатели
          отслеживали. Выходило не так подробно, как по зданиям, но все равно
          получали такую информацию, которую руководитель или заказчик вообще
          никогда не получал, а сейчас он получает это каком-то едином виде.
          Линейные объекты мы разбивали по этапам, по пикетам, то есть было
          достаточно подробно. Потом уже начали на зданиях внедрять
        </p>
        <h3 className={cn('font-w-600', s.text)}>
          Очень интересный кейс, у нас как раз многие потенциальные клиенты
          спрашивают как наш продукт может быть использован на линейных
          объектах. Поподробнее, можешь рассказать, вы в DASHBOARD все ведете,
          подтягиваете туда данные из excel и из моделей?
        </h3>
        <p className={s.text}>
          Если про линейный объект говорим, то нет. Там 3D-модель
          разрабатывалась не нами и она разрабатывалась в Робуре и далее к нам
          она выгружалась в IFC не с наборами, конструкциями или
          характеристиками какими-то, а по сути просто поверхности, плоские,
          т.е. мы фактически ее вставляли в DASHBOARD, чтобы ее можно было
          посидеть покрутить. Но снимать с нее объемы было не возможно, потому
          что их там просто не было. 3D использовалась как картинка по большей
          части. Единственное, что мы заметили, ну тут в целом вопрос к
          возможностям АСС (BIM 360) и IFC, иногда геометрию немного ломало и
          модель отображалась некорректно, куда-то улетали какие-то элементы. И
          тут уже была видна динамика, чем длиннее эта модель, тем хуже она
          отображается, поэтому мы ее дробили на части. На этом объекте также
          были площадные сооружения, например Пункты Взимания Оплаты, кто по
          платным дорогам ездить знает как это выглядит, по сути навес над
          дорогой, вот навес этот был в Revit сделан, какие-то небольшие,
          технические или модульные здания тоже были в Revit запроектированы и
          соответственно все это подгружалось к модели дороги и далее
          выгружалось в DASHBOARD как моделька, чтобы руководитель или заказчики
          могли открыть и посмотреть.
        </p>
        <div className={cn('flex justify-center w-100 my-6')}>
          <StaticImage
            className={cn(s.boxShadow, 'm-2')}
            src="../../assets/images/news/green-success-storie/image-4.jpg"
            alt="Скриншоты Дашбордов со смартфона"
            title="Скриншоты Дашбордов со смартфона"
            placeholder="blurred"
          />
          <StaticImage
            className={cn(s.boxShadow, 'm-2')}
            src="../../assets/images/news/green-success-storie/image-5.jpg"
            alt="Скриншоты Дашбордов со смартфона"
            title="Скриншоты Дашбордов со смартфона"
            placeholder="blurred"
          />
          <StaticImage
            className={cn(s.boxShadow, 'm-2')}
            src="../../assets/images/news/green-success-storie/image-6.jpg"
            alt="Скриншоты Дашбордов со смартфона"
            title="Скриншоты Дашбордов со смартфона"
            placeholder="blurred"
          />
        </div>
        <p className={cn(s.text, 'has-text-centered')}>
          Скриншоты Дашбордов со смартфона
        </p>
        <h3 className={cn('font-w-600', s.text)}>
          С дрона делали фотографии, видеосъемку или панорамные Фото360?
        </h3>
        <p className={s.text}>
          Делали фотографии и видео, но в SIGNAL не грузили и на том этапе в
          INSPECTION c дрона ничего не подгружали. Для INSPECTION мы
          использовали именно камеру на штативе в нескольких участках, допустим
          брали 10 участков, с разной какой-то удаленностью и периодически
          фоткали раз в две недели и таким образом сравнивали, как продвигается
          стройка.
        </p>
        <h3 className={cn('font-w-600', s.text)}>
          А как давно используете SIGNAL?
        </h3>
        <p className={s.text}>
          С января 22 года, вот через 3 месяца будет 2 года.
        </p>
        <h3 className={cn('font-w-600', s.text)}>
          Какие задачи стояли вначале, какие боли или проблемы смогли решить с
          помощью SIGNAL?
        </h3>
        <p className={s.text}>
          Было задача каким-то образом развивать BIM не только в проектировании,
          потому что там уже было все понятно, мы уже работали и хотелось как-то
          условно оцифровать стройку, начать работать с этими BIM-моделями на
          стройке, чтобы они не заканчивали свою жизнь у заказчика в архиве. И,
          по-моему немного было решений, я сейчас даже не вспомню, что мы еще
          тогда рассматривали, но SIGNAL тогда нас привлек связкой с BIM 360,
          дал возможность расширить те функции, которых так не хватало, например
          связь с Замечаниями, штампы в производство работ, ну и поддержка ЭЦП
          была очень интересна. Мы несколько презентаций провели у себя внутри,
          DASHBOARD всем понравился, и перед тем как внедрять у себя мы
          показывали нашим заказчикам, что вот потенциально, если мы для вашего
          объекта будем такую историю вести, будет интересно? Сказали что да.
          Понравилось, что не надо глубоко в техническую часть погружаться,
          дашборды посмотрели, всю основную информацию глянули, какое-то
          совещание провели и посмотрели как идет в процесс. Плюс у себя внутри
          компании обсудили и решили, что будем внедрять, попробуем, и на тот
          момент был один текущий объект это как раз автомобильные дороги,
          поэтому на них собственно и начали.
        </p>
        <div className="flex">
          <StaticImage
            className={cn(s.imageBlock, 'mb-0')}
            src="../../assets/images/news/green-success-storie/image-7.jpg"
            alt="SIGNAL DASHBOARD с инфографикой и аналитикой"
            title="SIGNAL DASHBOARD с инфографикой и аналитикой"
            placeholder="blurred"
          />
        </div>
        <p className={cn(s.text, 'has-text-centered')}>
          35+ дашбордов с инфографикой и аналитикой
        </p>
        <h3 className={cn('font-w-600', s.text)}>
          Сейчас SIGNAL используется на всех проектах?
        </h3>
        <p className={s.text}>
          Да, сейчас этой в основном площадные объекты, линейных сейчас в работе
          нет. Один из крупных объектов, это строительство инфекционной больницы
          в Новосибирской области, мы там выступаем в роли стройконтроля. Объект
          достаточно большой, это комплекс зданий, их порядка 18, все они
          строятся параллельно и вот там INSPECTION конечно супер позволяет
          отслеживать прогресс. Даже иногда по фото находили какие-то ошибки,
          заказчик на них находил какие-то недочеты или неразрешенные вопросы
          или технические специалисты по фоткам замечали то, что сразу в живую
          не заметили на объекте.
        </p>
        <div className="flex">
          <StaticImage
            className={cn(s.boxShadow, 'm-4')}
            src="../../assets/images/news/green-success-storie/image-8.jpg"
            alt="Многопрофильный медицинский центр"
            title="Многопрофильный медицинский центр"
            placeholder="blurred"
          />
          <StaticImage
            className={cn(s.boxShadow, 'm-4')}
            src="../../assets/images/news/green-success-storie/image-9.jpg"
            alt="Мостовой переход через р.Лена в районе Якутска"
            title="Мостовой переход через р.Лена в районе Якутска"
            placeholder="blurred"
          />
        </div>
        <p className={cn(s.text, 'ml-4')}>
          Слева: Многопрофильный медицинский центр
        </p>
        <p className={cn(s.text, 'ml-4')}>
          Справа: Мостовой переход через р.Лена в районе Якутска
        </p>

        <h3 className={cn('font-w-600', s.text)}>
          Обычно на медицинских объектах много инженерки, как у вас?
        </h3>
        <p className={s.text}>
          Да, очень много. Там очень насыщенные системы вентиляции, системы
          подачи кислорода и многое другое по части технологии, все эти системы
          у нас в Revit разработаны
        </p>
        <h3 className={cn('font-w-600', s.text)}>
          C моделью фото360 сравниваете? В TOOLS есть функция для создания точки
          съемки 360 в модели, которую в дальнейшем можно выгрузить в INSPECTION
          и таким образом сравнить модель с фактом.
        </h3>
        <p className={s.text}>
          Хотелось бы, но там такой объект, когда параллельно идет стройка и
          где-то местами корректируется документация, поэтому не всегда есть
          самая последняя версия модели, или наоборот, она изменяется под то,
          что уже сделано. Ну как обычно это бывает. Но на других объектах мы
          это делаем.
        </p>
        <div className="flex">
          <StaticImage
            className={cn(s.imageBlock, 'mb-0')}
            src="../../assets/images/news/green-success-storie/image-10.png"
            alt="Сравнение План/Факт в INSPECTION"
            title="Сравнение План/Факт в INSPECTION"
            placeholder="blurred"
          />
        </div>
        <p className={cn(s.text, 'has-text-centered')}>
          Сравнение План/Факт в INSPECTION
        </p>
        <h3 className={cn('font-w-600', s.text)}>
          Используете ли SIGNAL в своих международных проектах??
        </h3>
        <p className={s.text}>
          Именно строительный контроль или там что-то связанное со стройками, у
          нас еще таких объектов нет, пока это все на этапе концепции. Мы там
          проектируем, разрабатываем концепты проектов, поэтому там просто пока
          негде использовать. Но потенциально, мы конечно рассматриваем там
          использование SIGNAL и на встречах с потенциальными заказчиками об
          этом говорим и показываем.
        </p>
        <h3 className={cn('font-w-600', s.text)}>
          Как вообще изменились процессы взаимодействия, как работали до
          использования SIGNAL и как сейчас, может сможешь какие-то цифры
          привести?
        </h3>
        <p className={s.text}>
          В цифрах точно нет, не смогу дать такой информации, и думаю что даже
          специалисты, кто использует, тоже не готовы. Но здесь, однозначно, все
          голосуют двумя руками за INSPECTION, потому что по его функционалу
          вообще вопросов ни у кого нет. И самим удобно, и заказчикам всем
          нравится. Единственное, что есть мысли, как бы еще автоматизировать
          сам процесс хождения по объекту и фотографирования, хотим как-то его
          роботизировать, чтобы не человек приезжал и ходил по стройплощадке, а
          делать это автоматически, в ближайшее время займемся этим вопросом.
          DASHBOARD позволяет быстро показывать всю необходимую информацию по
          объекту строительства, совещания с клиентами и подрядчиками стали
          проходить более эффективно, не надо какие-то презентации разрабатывать
          отдельно, это тоже плюс, потому что раньше, чтобы что-то показать,
          нужно было в Powerpoint составлять отчеты, прикреплять фотки, цифры,
          показывать как процесс поменялся, а сейчас это все в одном месте
          отслеживается и там всю динамику видно. Сказать, что прям сильно
          процесс изменился, тут не скажешь, но где-то локально он стал попроще,
          где-то стал более автоматизированным. Также было два объекта, где мы
          использовали VALUE, проставляли замечания в DOCS, потом их выгружали в
          виде предписания, было удобно, но сейчас на некоторых объектах
          заказчик обязал нас использовать ТехЗор для строительного контроля, и
          процесс фиксирования замечания проходит там, поэтому там VALUE пока не
          используется в этом виде. Но при этом мы не готовы отказаться от
          INSPECTION и DASHBOARD. А еще не все заказчики готовы хранить все
          модели, документацию и замечания в BIM 360. Ну и плюс у нас все равно
          есть ограниченный набор лицензии, которые мы можем дать, и даже если
          бы захотелось, тогда 50 человек дополнительно включить, не получится,
          мы стараемся как-то ограничивать.
        </p>
        <h3 className={cn('font-w-600', s.text)}>
          Вы подвели свой ответ к моему следующему вопросу. У нас вышел SIGNAL
          DOCS, в котором мы реализовали функционал BIM360, но лучше, удобнее и
          с интересными дополнительными фичами.
        </h3>
        <p className={s.text}>
          Да, я видел слышал. в целом история для нас очень интересная. Вопрос
          будет в цене, наверное, и функционале. Но потенциально, почему нет. На
          самом деле мы уже рассматривали другие аналоги BIM 360. Но они пока
          сырые, не готовы и вопрос цены остается. Поэтому мы готовы
          протестировать и возможно даже купим, т.к заказчиков, которых не
          устраивает хранение в BIM 360 становится все больше и количество
          объектов у нас растет, а в подписках BIM 360 мы ограничены, поэтому
          использование DOCS может быть интересная для нас история.
        </p>
        <h3 className={cn('font-w-600', s.text)}>
          Как в дальнейшем видите развитие вашей компании и использование
          SIGNAL?
        </h3>
        <p className={s.text}>
          Про SIGNAL DOCS я уже сказал. А в будущем мы бы хотели, чтобы все
          проекты сопровождались строительной BIM-моделью, это позволит намного
          эффективнее и нагляднее отслеживать план-график реализации проекта.
          Такая информация будет полезна и клиенту, и нам, и подрядчикам. Не все
          еще готовы к этому, но мы видим тенденцию к увеличению интереса к BIM
          на стройке как со стороны клиентов и инвесторов, так и со стороны
          подрядных организаций, и то, как продукты компании SIGNAL поддерживают
          и вписываются в эту тенденцию
        </p>
      </NewsSection>
    </>
  )
}

export default GreenSuccessStorie
